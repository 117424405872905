"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

(function () {
  "use strict";

  var TopBarController = function () {
    /** @ngInject */
    function TopBarController(AuthService, $timeout, $window, $rootScope, CustomersService, ModalService, CustomListsService) {
      _classCallCheck(this, TopBarController);

      this.AuthService = AuthService;
      this.$rootScope = $rootScope;
      this.$timeout = $timeout;
      this.$window = $window;
      this.CustomersService = CustomersService;
      this.ModalService = ModalService;
      this.CustomListsService = CustomListsService;
    }

    _createClass(TopBarController, [{
      key: "$onInit",
      value: function $onInit() {
        if (this.$rootScope.$super) {
          //console.log('user is $super', this.$rootScope.$user)

          this.loadCustomers();
        }

        var changePassword = localStorage.getItem("$ChangePassword");
        if (changePassword === "true") {
          localStorage.setItem("$ChangePassword", "false");
          this.changePassword();
        }
      }
    }, {
      key: "emit",
      value: function emit() {
        if (this.$rootScope.$super) {
          localStorage.removeItem("last-depa-form");
          localStorage.setItem("$selectedCustomerId", this.$rootScope.$selectedCustomerId);
          location.reload();
        }
      }
    }, {
      key: "loadCustomers",
      value: function loadCustomers() {
        var _this = this;

        if (this.$rootScope.$super) {
          this.CustomersService.getSelectArray().then(function (array) {
            var filterArray = array.filter(function (element) {
              var condition = element.active == true || element.name === "LVR";
              if (_this.$rootScope.$withInactiveCustomers) {
                condition = true;
              }
              return condition;
            });

            if (!filterArray.map(function (customer) {
              return customer.value;
            }).includes(_this.$rootScope.$selectedCustomerId)) {
              _this.$rootScope.$withInactiveCustomers = true;

              filterArray = array.filter(function (element) {
                var condition = element.active == true || element.name === "LVR";
                if (_this.$rootScope.$withInactiveCustomers) {
                  condition = true;
                }
                return condition;
              });
            }

            _this.$timeout(function () {
              _this.list = filterArray;
            }, 0);
          });
        }
      }
    }, {
      key: "openTutorial",
      value: function openTutorial() {
        this.$rootScope.openTutorial();
      }
    }, {
      key: "logout",
      value: function logout() {
        this.AuthService.logout();
      }
    }, {
      key: "changePassword",
      value: function changePassword() {
        console.log("change password");

        this.ModalService.openComponent("changePassword", { size: "lg" }).then(function () {
          console.log("changePassword ok");
        }).catch(function (err) {
          console.log("err", err);
        });
      }
    }, {
      key: "setup2FA",
      value: function setup2FA() {
        this.ModalService.openComponent("setupTwoFactors", { size: "md" }).then(function () {
          console.log("setup2FA ok");
        }).catch(function (err) {
          console.log("err", err);
        });
      }
    }]);

    return TopBarController;
  }();

  angular.module("menu").component("appTopBar", {
    templateUrl: "app/menu/topBar/topBar.html",
    controller: TopBarController
  });
})();