"use strict";

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

angular.module("my-ag-grid").service("AgGridService", ["moment", function (moment) {
  var that = this;

  that.defaultWidth = 80;

  that.group = function (name, items, objectToMerge) {
    return _.merge({
      headerName: name,
      headerTooltip: name,
      groupId: name.replace(/\W_/g, "") + "GroupId",
      children: items,
      marryChildren: true
    }, objectToMerge);
  };

  that.click = function (fn, ctx) {
    return {
      cellRenderer: function cellRenderer(params) {
        if (params.value) {
          // console.log('params', params)
        }

        var eDiv = document.createElement("div");
        if (params.value) {
          eDiv.innerHTML = '<span class="underline--double" data-toggle="tooltip" title="' + params.valueFormatted + '">' + params.valueFormatted + "</span>";
          eDiv.addEventListener("click", function () {
            fn(params.data, ctx);
          });
        } else {
          eDiv.innerHTML = "";
        }

        return eDiv;
      }
    };
  };

  that.button = function (field, name, fn, ctx, objectToMerge) {
    return _.merge({
      headerName: name,
      headerTooltip: name,
      export: false,
      cellRenderer: function cellRenderer(params) {
        var eDiv = document.createElement("div");

        if (params.value) {
          eDiv.innerHTML = "<span id=" + params.value.id + ' style="font-size: 80%" class="btn label btn-' + params.value.color + '">' + params.value.text + "</span>";

          eDiv.addEventListener("click", function () {
            fn(params.data, ctx);
          });
        } else {}

        return eDiv;
      },
      field: field,
      width: that.defaultWidth,
      filter: false,
      filterParams: {
        valueGetter: function valueGetter(params) {
          return params.data[field].text;
        }
      },
      sortable: true,
      comparator: function comparator(valueA, valueB, nodeA, nodeB, isInverted) {
        if (nodeA && nodeA.data && nodeB && nodeB.data) {
          if (nodeA.data[field].text == nodeB.data[field].text) return 0;
          return nodeA.data[field].text > nodeB.data[field].text ? 1 : -1;
        }
        return -1;
      },

      suppressColumnsToolPanel: true,
      suppressNavigable: true,
      lockVisible: true,
      suppressMovable: false,
      suppressFiltersToolPanel: true,
      suppressMenu: true,

      enablePivot: false,

      suppressResize: true, // TODO: PWA ?
      suppressSizeToFit: true, // TODO: PWA ?

      suppressFilter: true,
      suppressSorting: true
    }, objectToMerge);
  };

  that.buttons = function (field, name, fn, ctx, objectToMerge) {
    return _.merge({
      headerName: name,
      headerTooltip: name,
      export: false,
      cellRenderer: function cellRenderer(params) {
        var eDiv = document.createElement("div");

        if (params.value) {
          eDiv.innerHTML = '<span class="label label-' + params.value.color + '">' + params.value.text + "</span>";

          eDiv.addEventListener("click", function () {
            fn(params.data, ctx);
          });
        } else {}

        return eDiv;
      },
      field: field,
      width: that.defaultWidth,
      filter: false,
      sortable: false,

      suppressColumnsToolPanel: true,
      suppressNavigable: true,
      lockVisible: true,
      suppressMovable: true,
      suppressFiltersToolPanel: true,
      suppressMenu: true,

      enablePivot: false,

      suppressResize: true,
      suppressSizeToFit: true,

      suppressFilter: true,
      suppressSorting: true
    }, objectToMerge);
  };

  that.icon = function (icon, fn, ctx) {
    return {
      headerName: "",
      export: false,
      cellRenderer: function cellRenderer(params) {
        var eDiv = document.createElement("div");

        eDiv.innerHTML = '<i class="fa fa-' + icon + ' gi-2x"></i>';
        var eButton = eDiv.querySelectorAll(".fa-" + icon)[0];

        eButton.addEventListener("click", function () {
          if (icon == "trash") {
            swal({
              //title: "Are you sure?",
              text: "Etes-vous sûr de vouloir supprimer cet élément ?",
              icon: "warning",
              dangerMode: true,
              buttons: {
                cancel: "Non",

                confirm: "Oui"
              }
            }).then(function (willDelete) {
              console.log("sure ?=" + willDelete);
              if (willDelete) {
                fn(params.data, ctx);
              }
            });
          } else {
            fn(params.data, ctx);
          }
        });

        return eDiv;
      },
      width: 30,
      minWidth: 30,
      maxWidth: 30,

      filter: false,
      sortable: false,

      suppressColumnsToolPanel: true,
      suppressNavigable: true,
      lockVisible: true,
      suppressMovable: true,
      suppressFiltersToolPanel: true,
      suppressMenu: true,

      enablePivot: false,

      suppressResize: true,
      suppressSizeToFit: true,

      suppressFilter: true,
      suppressSorting: true
    };
  };

  that.checkbox = function (objectToMerge) {
    var _$merge;

    return _.merge((_$merge = {
      headerName: "",
      field: "checkbox",
      export: false,

      enableRowGroup: false,
      enablePivot: false,

      width: 40,
      minWidth: 40,
      maxWidth: 40,

      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,

      filter: false,
      sortable: false,

      suppressColumnsToolPanel: true,
      suppressNavigable: true,
      lockVisible: true,
      suppressMovable: true,
      suppressFiltersToolPanel: true,
      suppressMenu: true

    }, _defineProperty(_$merge, "enablePivot", false), _defineProperty(_$merge, "suppressResize", true), _defineProperty(_$merge, "suppressSizeToFit", true), _defineProperty(_$merge, "suppressFilter", true), _defineProperty(_$merge, "suppressSorting", true), _$merge), objectToMerge);
  };

  //TO DO : à supprimer
  that.delete = {
    headerName: "",
    cellRenderer: function cellRenderer(params) {
      //return '<i class="fa fa-trash gi-2x" ng-click="vm.onEvent(\'delete\',data)"></i>'
      return '<i class="fa fa-trash gi-4x" mwl-confirm title="" message="Confirmez la suppression ?" confirm-text="OUI" cancel-text="NON" placement="left" on-confirm="vm.onEvent(\'delete\',data)" on-cancel="" confirm-button-type="danger" cancel-button-type="default"></i>';
    },
    width: 21,
    minWidth: 21,
    maxWidth: 21,
    suppressResize: true,
    suppressSizeToFit: true,
    suppressMovable: true,
    suppressFilter: true,
    suppressSorting: true
  };

  that.text = function (field, name) {
    var width = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : that.defaultWidth;
    var objectToMerge = arguments[3];

    return _.merge({
      headerName: name,
      tooltipValueGetter: function tooltipValueGetter(p) {
        return p.value;
      },
      headerTooltip: name,
      field: field,
      width: width,
      enableRowGroup: true,
      enablePivot: true,

      resizable: true,

      menuTabs: ["filterMenuTab", "generalMenuTab", "columnsMenuTab"],

      //floatCell: true,

      filterParams: {
        //cellRenderer: CountryCellRenderer,
        cellHeight: 20,
        newRowsAction: "keep",
        selectAllOnMiniFilter: true,
        clearButton: true,

        comparator: function comparator(a, b) {
          if (a === "Vide" || !a) return -1;
          if (b === "Vide" || !b) return 1;
          return a.localeCompare(b);
        },

        valueFormatter: function valueFormatter(params) {
          if (!params.value) {
            return "Vide";
          } else {
            return params.value;
          }
        }
      },

      filter: true,
      valueFormatter: that.formatText,
      sortable: true
    }, objectToMerge);
  };

  that.formatText = function (params) {
    if (!params.value) {
      params.value = null;
      return;
    } else {
      return params.value;
    }
  };

  that.date = function (field, name) {
    var width = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : that.defaultWidth;
    var objectToMerge = arguments[3];

    return _.merge({
      headerName: name,
      tooltipValueGetter: function tooltipValueGetter(p) {
        return p.value ? moment(p.value).format("DD/MM/YYYY") : "";
      },
      headerTooltip: name,
      field: field,
      width: width,
      enableRowGroup: true,
      resizable: true,

      menuTabs: ["filterMenuTab", "generalMenuTab", "columnsMenuTab"],

      filter: "date",
      valueFormatter: that.formatDate,

      comparator: function comparator(valueA, valueB, nodeA, nodeB, isInverted) {
        if (!valueA) return -1;
        if (!valueB) return 1;
        if (new Date(valueA) === new Date(valueB)) return 0;
        return new Date(valueA) > new Date(valueB) ? 1 : -1;
      },

      filterParams: {
        //cellRenderer: CountryCellRenderer,
        cellHeight: 20,
        newRowsAction: "keep",
        selectAllOnMiniFilter: true,
        clearButton: true,

        valueFormatter: that.formatDate,

        comparator: function comparator(filterLocalDateAtMidnight, cellValue) {
          if (cellValue == null) return -1;
          var cellDate = moment(cellValue).tz("Europe/Paris").toDate();

          if (moment(filterLocalDateAtMidnight).format("DD/MM/YYYY") === moment(cellDate).format("DD/MM/YYYY")) {
            return 0;
          } else if (moment(cellDate).startOf("day").toDate() < moment(filterLocalDateAtMidnight).startOf("day").toDate()) {
            return -1;
          } else {
            return 1;
          }
        }
      },

      cellClass: "date-iso"
    }, objectToMerge);
  };

  that.formatDate = function (params) {
    return params.value ? moment(params.value).format("DD/MM/YYYY") : null;
  };

  that.hour = function (field, name) {
    var width = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : that.defaultWidth;
    var objectToMerge = arguments[3];

    return _.merge({
      headerName: name,
      tooltipValueGetter: function tooltipValueGetter(p) {
        return p.value ? moment(p.value).format("HH:mm") : "";
      },
      headerTooltip: name,
      field: field,
      width: width,
      enableRowGroup: true,
      resizable: true,

      menuTabs: ["filterMenuTab", "generalMenuTab", "columnsMenuTab"],

      filter: "date",
      valueFormatter: that.formatHour,

      filterParams: {
        //cellRenderer: CountryCellRenderer,
        cellHeight: 20,
        newRowsAction: "keep",
        selectAllOnMiniFilter: true,
        clearButton: true
      }
    }, objectToMerge);
  };

  that.formatHour = function (params) {
    return params.value ? moment(params.value).format("HH:mm") : null;
  };

  that.number = function (field, name) {
    var width = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : that.defaultWidth;
    var objectToMerge = arguments[3];

    return _.merge({
      headerName: name,
      tooltipValueGetter: function tooltipValueGetter(p) {
        return p.value;
      },
      headerTooltip: name,
      field: field,
      width: width,
      filter: "number",
      resizable: true,
      sortable: true,

      menuTabs: ["filterMenuTab", "generalMenuTab", "columnsMenuTab"],

      comparator: function comparator(valueA, valueB, nodeA, nodeB, isInverted) {
        if (!Number.isInteger(valueA)) {
          return -1;
        } else if (!Number.isInteger(valueB)) {
          return 1;
        } else if (valueA == valueB) {
          return 0;
        } else {
          return valueA > valueB ? 1 : -1;
        }
      },

      filterParams: {
        //cellRenderer: CountryCellRenderer,
        cellHeight: 20,
        newRowsAction: "keep",
        selectAllOnMiniFilter: true,
        clearButton: true
      }
    }, objectToMerge);
  };

  that.currency = function (field, name) {
    var width = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : that.defaultWidth;
    var objectToMerge = arguments[3];

    return _.merge({
      headerName: name,
      tooltipValueGetter: function tooltipValueGetter(p) {
        return typeof p.value === "number" ? p.value + " €" : "";
      },
      headerTooltip: name,
      field: field,
      width: width,
      filter: "number",
      resizable: true,

      menuTabs: ["filterMenuTab", "generalMenuTab", "columnsMenuTab"],

      sortable: true,
      cellClass: "currency-format",

      comparator: function comparator(valueA, valueB, nodeA, nodeB, isInverted) {
        var valA = Number.isInteger(valueA) ? Number(valueA) : Number(String(valueA).replace(",", "."));
        var valB = Number.isInteger(valueB) ? Number(valueB) : Number(String(valueB).replace(",", "."));

        var result = void 0;

        if (valA == valB) {
          result = 0;
        } else if (typeof valA != "number" || isNaN(valA)) {
          result = -1;
        } else if (typeof valB != "number" || isNaN(valB)) {
          result = 1;
        } else {
          result = valA > valB ? 1 : -1;
        }

        return result;
      },

      valueFormatter: that.formatCurrency,

      filterParams: {
        //cellRenderer: CountryCellRenderer,
        cellHeight: 20,
        newRowsAction: "keep",
        selectAllOnMiniFilter: true,
        clearButton: true
      }
    }, objectToMerge);
  };

  that.formatCurrency = function (params) {
    return params.value ? params.value + " €" : null;
  };

  that.bool = function (field, name) {
    var width = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : that.defaultWidth;
    var objectToMerge = arguments[3];

    return _.merge({
      headerName: name,
      tooltipValueGetter: function tooltipValueGetter(p) {
        return p.value === true ? "Oui" : p.value === false ? "Non" : "";
      },
      headerTooltip: name,
      field: field,
      width: width,
      valueFormatter: that.formatBool,
      resizable: true,

      menuTabs: ["filterMenuTab", "generalMenuTab", "columnsMenuTab"],

      filter: true,

      sortable: true,
      comparator: function comparator(valueA, valueB, nodeA, nodeB, isInverted) {
        if (nodeA && nodeA.data && nodeB && nodeB.data) {
          if (nodeA.data[field] == nodeB.data[field]) return 0;
          return nodeA.data[field] > nodeB.data[field] ? 1 : -1;
        }
        return -1;
      },

      filterParams: {
        //cellRenderer: CountryCellRenderer,
        cellHeight: 20,
        newRowsAction: "keep",
        selectAllOnMiniFilter: true,
        clearButton: true,

        valueFormatter: function valueFormatter(params) {
          console.log("filter params", params);
          if (params.value === "true") {
            return "OUI";
          } else if (params.value === "false") {
            return "NON";
          } else {
            return " ";
          }
        }
      }
    }, objectToMerge);
  };

  that.formatBool = function (params) {
    if (params.value === true) {
      return "OUI";
    } else if (params.value === false) {
      return "NON";
    } else {
      return;
    }
  };
}]);